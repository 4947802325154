@font-face {
  font-family: 'Somatic';
  src: local('Somatic'), url(./fonts/Somatic-Rounded.otf) format('truetype');
}

@font-face {
  font-family: 'ArbFONTS-DroidKufi';
  src: local('ArbFONTS-DroidKufi'), url(./fonts/ArbFONTS-DroidKufi-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ArbFONTS-DroidKufi-Bold';
  src: local('ArbFONTS-DroidKufi-Bold'), url(./fonts/ArbFONTS-DroidKufi-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

body {
  font-family: 'ArbFONTS-DroidKufi', 'Poppins-Regular';
}